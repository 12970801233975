import Vue from 'vue'
import Router from 'vue-router'
import auth from './middleware/auth'
import log from './middleware/log'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL || '/dev.uploader.hgk.fhnw.ch/',
  routes: [
    {
      path: '/',
      component: () => import('./views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('./views/dashboard/Dashboard'),
          meta: {
            middleware: [auth, log],
          },
        },
        // Nodes
        {
          name: 'Nodes',
          path: '/nodes/:path*',
          component: () => import('./views/dashboard/Nodes'),
          meta: {
            middleware: [auth, log],
          },
        },
        // Search
        {
          name: 'Search',
          path: '/search-table/:path*',
          component: () => import('./views/dashboard/SearchTable'),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('./views/dashboard/pages/UserProfile'),
          meta: {
            middleware: [auth, log],
          },
        },
        // Auth and login
        {
          name: 'Login Page',
          path: '/login',
          component: () => import('./views/pages/Login'),
        },
        {
          name: 'Logout',
          path: '/logout',
          component: () => import('./views/pages/Logout'),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          name: 'Authentication',
          path: '/auth',
          component: () => import('./views/pages/Auth'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('./views/dashboard/component/Notifications'),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('./views/dashboard/component/Icons'),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('./views/dashboard/component/Typography'),
          meta: {
            middleware: [auth, log],
          },
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('./views/dashboard/tables/RegularTables'),
          meta: {
            middleware: [auth, log],
          },
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('./views/dashboard/maps/GoogleMaps'),
          meta: {
            middleware: [auth, log],
          },
        },
      ],
    },
  ],
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory (context, middleware, index) {
    const subsequentMiddleware = middleware[index]
    // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next
       return (...parameters) => {
       // Run the default Vue Router `next()` callback first.
         context.next(...parameters)
        // Then run the subsequent Middleware with a new
         // `nextMiddleware()` callback.
         const nextMiddleware = nextFactory(context, middleware, index + 1)
       subsequentMiddleware({ ...context, next: nextMiddleware })
      }
  }

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
          ? to.meta.middleware
              : [to.meta.middleware]

            const context = {
              from,
              next,
              router,
              to,
            }
        const nextMiddleware = nextFactory(context, middleware, 1)

        return middleware[0]({ ...context, next: nextMiddleware })
      }
    return next()
  })

export default router
