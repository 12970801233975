import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Toasted from 'vue-toasted'
import { search } from './store.search'

Vue.use(Vuex)
Vue.use(Toasted)
const API_BASE_URL = 'https://api.hgkforms.campusderkuenste.ch'
const DIGMA_HEADERS = {
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    // Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzM4NCJ9.eyJpc3MiOiJhdXRoLmhnay5maG53LmNoXC9oZ2tmb3JtcyIsIm5iZiI6MTYxNjYwMzgzNiwiaWF0IjoxNjE2NjAzODM2LCJleHAiOjE3MTY2MDAyNDAsInVzZXJJZCI6IjEiLCJlbWFpbCI6ImdyYXppYW5vLnZvbmFsbG1lbkBmaG53LmNoIiwiZmlyc3ROYW1lIjoiR3Jhemlhbm8iLCJsYXN0TmFtZSI6InZvbiBBbGxtZW4iLCJob21lT3JnIjoiZmhudy5jaCIsImdyb3VwcyI6ImZobndcL2hnaztmaG53XC9zdGFmZjtmaG53XC91c2VyO2dsb2JhbFwvZGlnbWE7Z2xvYmFsXC9ndWVzdDtnbG9iYWxcL3N0YWZmO2dsb2JhbFwvdXNlcjtoZ2tcL2JhO25ldFwvZmhudyJ9.660hLy9HxugAJyE2-aezY91NfZK2VZ6ByKo9IEaEhLeaV3wgNztF2bWsQGmBewYT',
  },
}

export default new Vuex.Store({
  modules: {
    search,
  },
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    current: {},
    currentNode: null,
    currentPersons: null,
    currentPersonsTotal: null,
    currentSearch: null,
    currentSearchTotal: 0,
    editItem: {},
    nodes: [{
      name: '',
    }],
    objects: [],
    currentObject: {},
    user: null,
    auth: false,
    debug: null,
  },
  mutations: {
    SET_NODES (state, nodes) {
      state.nodes = nodes
    },
    SET_OBJECTS (state, o) {
      state.objects = o
    },
    SET_CURRENT_OBJECT (state, o) {
      state.currentObject = o
    },
    SET_DEBUG (state, item) {
      state.debug = item
    },
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_CURRENT (state, d) {
      state.current = d
    },
    SET_CURRENT_NODE (state, d) {
      state.currentNode = d
    },
    SET_CURRENT_PERSON (state, d, c) {
      state.currentPersons = d
      state.currentPersonsTotal = parseInt(c) || null
    },
    SET_CURRENT_SEARCH (state, d) {
      state.currentSearchTotal = d.total
      state.currentSearch = d.items
    },
    SET_AUTH (state, jwt) {
      if (!jwt) {
        state.auth = false
        state.user = null
        return
      }
      const parseJwt = (t) => {
        try {
          return JSON.parse(atob(t.split('.')[1]))
        } catch (e) {
          return null
        }
      }
      state.user = parseJwt(jwt)
      localStorage.setItem('jwt', jwt)
      state.auth = true
    },
  },
  actions: {
    readNodes: async function ({ commit }, data) {
      commit('SET_AUTH', localStorage.getItem('jwt'))
      await axios.get(API_BASE_URL + '/nodes/hgk', {
        ...DIGMA_HEADERS,
      }).then(async (response) => {
        if (response.data) {
          const arr = []
          if (response.data) {
            commit('SET_DEBUG', response.data)
            const obj = {
              id: response.data.id,
              name: response.data.metadata.name,
              path: response.data.fullpath,
              description: response.data.metadata.description,
              childcount: response.data.childcount,
              children: response.data.children.map(e => {
                if (e.childcount > 0) {
                  return Object.assign({}, {
                    name: e.metadata.name,
                    path: e.fullpath,
                    description: response.data.metadata.description,
                    childcount: e.childcount,
                    slug: e.slug,
                    children: [],
                  })
                } else {
                  return Object.assign({}, {
                    name: e.metadata.name,
                    id: e.id,
                    slug: e.slug,
                    path: e.fullpath,
                    description: response.data.metadata.description,
                  })
                }
              }) || [],
            }
            arr.push(obj)
          }
          commit('SET_NODES', arr)
        }
      })
    },
    async readNode ({ store, commit, state }, data) {
      await axios.get(`${API_BASE_URL}${data.path}`, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        commit('SET_CURRENT_NODE', response.data)
        return response
      })
    },
    async createNode ({ store }, data) {
      await axios.post(`${API_BASE_URL}/nodes${data.path}/${data.payload.slug}`, data.payload, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        if (response.status === 200) {
          Vue.toasted.success('Node created').goAway(2000)
          return response
        } else {
          Vue.toasted.error('There was an error').goAway(2000)
        }
      })
    },
    async updateNode ({ store }, data) {
      await axios.put(`${API_BASE_URL}/nodes${data.path}`, data.payload, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        if (response.status === 200) {
          console.log(response)
          Vue.toasted.success('Node updated').goAway(2000)
          return response
        } else {
          Vue.toasted.error('There was an error').goAway(2000)
        }
      })
    },
    async deleteNode ({ store }, data) {
      await axios.delete(`${API_BASE_URL}/nodes${data.path}`, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        if (response.status === 200 || response.status === 204) {
          Vue.toasted.success('Node deleted').goAway(2000)
          return response
        } else {
          Vue.toasted.error('There was an error').goAway(2000)
        }
        return response
      })
    },
    // Persons and Organizations
    async readPersonsAndOrgas ({ commit }) {
      const items = []
      await axios.get(`${API_BASE_URL}/persons`, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        items.push(...response.data)
      })
      await axios.get(`${API_BASE_URL}/organizations`, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        items.push(...response.data)
      })
      await axios.get(`${API_BASE_URL}/groups`, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        items.push(...response.data)
      })
      commit('SET_CURRENT_PERSON', items)
    },
    async createEntity ({ store }, data) {
      await axios.post(`${API_BASE_URL}/${data.type || 'persons'}`, data, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        Vue.toasted.success(response.data.displayName + ' created').goAway(2000)
        return response
      })
    },
    async updateEntity ({ store }, data) {
      return await axios.put(`${API_BASE_URL}/${data.type || 'persons'}/${data.id}`, data, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        Vue.toasted.success(data.displayName + ' updated').goAway(2000)
        return response
      })
    },
    async deleteEntity ({ store }, data) {
      await axios.delete(`${API_BASE_URL}/${data.type || 'persons'}/${data.id}`, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        Vue.toasted.success(data.displayName + ' deleted').goAway(2000)
        return response
      })
    },
    async readEntity ({ commit }, { query, type = 'default' }) {
      const items = []
      let total = 0
      await axios.get(`${API_BASE_URL}/entities${query ? '?' + query : ''}`, {
        ...DIGMA_HEADERS,
      }).then(({ data, headers }) => {
        total = headers['x-total-count']
        items.push(...data)
      }).finally(() => {
        // console.log(total)
        commit('SET_CURRENT_SEARCH', { items, total })
        commit('SET_CURRENT_PERSON', items, total)
      })
    },

    // Objects
    async createObject ({ store, dispatch, router }, data) {
      return await axios.post(`${API_BASE_URL}/object`, { node: data.path, ...data.payload }, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        console.log('created obj', response.data.id, response.data)
        Vue.toasted.success(response.data.title + ' created').goAway(2000)
        const obj = { id: response.data.id, ...data.payload }
        return dispatch('updateObject', obj)
      })
    },
    async readObjects ({ store, commit, state }, data) {
      return await axios.get(`${API_BASE_URL}${data.path}/objects`, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        commit('SET_OBJECTS', response.data)
        return response
      })
    },
    async readObject ({ store, commit, state }, data) {
      return await axios.get(`${API_BASE_URL}/object/${data.id}`, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        console.log(response, data, 'obj curr')
        commit('SET_CURRENT_OBJECT', response.data)
        return response
      })
    },
    async updateObject ({ store }, data) {
      return await axios.put(`${API_BASE_URL}/object/${data.id}`, data, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        Vue.toasted.success(data.displayName + ' updated').goAway(2000)
        return response
      })
    },
    async deleteObject ({ store, state }, data) {
      await axios.delete(`${API_BASE_URL}/object/${data.id}`, {
        ...DIGMA_HEADERS,
      }).then((response) => {
        const idx = state.objects.map(e => e.id).indexOf(data.id)
        if (idx > -1) {
          state.objects.splice(idx, 1)
        }
        Vue.toasted.success(data.displayName + ' deleted').goAway(2000)
        return response
      })
    },
  },
})
