import axios from 'axios'
const API_BASE_URL = 'https://api.hgkforms.campusderkuenste.ch'

const DIGMA_HEADERS = {
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        // Authorization: 'Bearer ' + localStorage.getItem('jwt'),
        Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzM4NCJ9.eyJpc3MiOiJhdXRoLmhnay5maG53LmNoXC9oZ2tmb3JtcyIsIm5iZiI6MTYxNjYwMzgzNiwiaWF0IjoxNjE2NjAzODM2LCJleHAiOjE3MTY2MDAyNDAsInVzZXJJZCI6IjEiLCJlbWFpbCI6ImdyYXppYW5vLnZvbmFsbG1lbkBmaG53LmNoIiwiZmlyc3ROYW1lIjoiR3Jhemlhbm8iLCJsYXN0TmFtZSI6InZvbiBBbGxtZW4iLCJob21lT3JnIjoiZmhudy5jaCIsImdyb3VwcyI6ImZobndcL2hnaztmaG53XC9zdGFmZjtmaG53XC91c2VyO2dsb2JhbFwvZGlnbWE7Z2xvYmFsXC9ndWVzdDtnbG9iYWxcL3N0YWZmO2dsb2JhbFwvdXNlcjtoZ2tcL2JhO25ldFwvZmhudyJ9.660hLy9HxugAJyE2-aezY91NfZK2VZ6ByKo9IEaEhLeaV3wgNztF2bWsQGmBewYT',
    },
}

export const search = {
    namespaced: true,
    state: {
        result: {
            items: [],
            total: 0,
        },
    },
    mutations: {
        SET_RESULT (state, payload) {
            state.result.items = payload.items
            state.result.total = payload.total
        },
    },
    actions: {
        async get ({ commit }, query) {
            const items = []
            let total = 0
            await axios.get(`${API_BASE_URL}/entities${query.title ? '?search=' + query.title + '&page=' + query.page + '&pagesize=' + query.size : '' + '?page=' + query.page + '&pagesize=' + query.size}`, {
                ...DIGMA_HEADERS,
            }).then(({ data, headers }) => {
                total = headers['x-total-count']
                items.push(...data)
            }).finally(() => {
                commit('SET_RESULT', { items, total })
            })
        },
    },
}
