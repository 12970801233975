export default function auth ({ next, to, router, $store, redirect }) {
    if (!localStorage.getItem('jwt')) {
        if (to.query && to.query['sso-auth']) {
            return router.push({ path: '/auth', query: { jwt: to.query['sso-auth'] } })
        } else {
            return router.push({ path: '/login' })
        }
    }
    return next()
}
